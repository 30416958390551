const CDN_ADDRESS = "https://resources.jetbrains.com/cai/eidos_231";
const EVENTS_API_URL = "https://kraken.labs.jb.gg/analytics/events/create";
const products = [
    {value: "jetbrains", name: "JetBrains"},
    {value: "jetbrainsai", name: "JetBrains AI"},
    {value: "aqua", name: "Aqua"},
    {value: "clion", name: "CLion"},
    {value: "datagrip", name: "DataGrip"},
    {value: "datalore", name: "Datalore"},
    {value: "dataspell", name: "DataSpell"},
    {value: "dotcover", name: "dotCover"},
    {value: "dotmemory", name: "dotMemory"},
    {value: "dotpeek", name: "dotPeek"},
    {value: "dottrace", name: "dotTrace"},
    {value: "fleet", name: "Fleet"},
    {value: "goland", name: "GoLand"},
    {value: "intellij-idea", name: "IntelliJ IDEA"},
    {value: "kotlin", name: "Kotlin"},
    {value: "mps", name: "MPS"},
    {value: "phpstorm", name: "PhpStorm"},
    {value: "pycharm", name: "PyCharm"},
    {value: "qodana", name: "Qodana"},
    {value: "resharper", name: "ReSharper"},
    {value: "resharper-cpp", name: "ReSharper C++"},
    {value: "rider", name: "Rider"},
    {value: "rubymine", name: "RubyMine"},
    {value: "space", name: "Space"},
    {value: "teamcity", name: "TeamCity"},
    {value: "toolbox", name: "Toolbox"},
    {value: "webstorm", name: "WebStorm"},
    {value: "youtrack", name: "YouTrack"},
    {value: "writerside", name: "Writerside"},
];
// Number of images per product
const MAX_IMAGE_NUMBER = {
    "jetbrains": 1003,
    "jetbrainsai": 2,
    "aqua": 1002,
    "clion": 1002,
    "datagrip": 1002,
    "datalore": 1003,
    "dataspell": 1002,
    "dotcover": 1002,
    "dotmemory": 1002,
    "dotpeek": 1002,
    "dottrace": 1002,
    "fleet": 1003,
    "goland": 1002,
    "intellij-idea": 1002,
    "kotlin": 1003,
    "mps": 1002,
    "phpstorm": 1002,
    "pycharm": 1002,
    "qodana": 1003,
    "resharper": 1002,
    "resharper-cpp": 1002,
    "rider": 1002,
    "rubymine": 1002,
    "space": 1002,
    "teamcity": 1003,
    "toolbox": 1003,
    "webstorm": 1002,
    "youtrack": 1003,
    "writerside": 3,
};

products.sort((a, b) => a.name.localeCompare(b.name));

const image = document.getElementById("generatedImage");
const nextBtn = document.getElementById("nextBtn");
const saveBtn = document.getElementById("saveBtn");
const styleSelect = document.getElementById("styleSelect");
const spinner = document.getElementById("spinner");
const errorMessage = document.getElementById("errorMessage");
const videoBtn = document.getElementById("videoBtn");

let currentImageNumber = 0;
populateStyleSelect();
nextBtn.addEventListener("click", () => {
    removeHash();
    showRandomImage();
});
styleSelect.addEventListener("change", () => showSpecificImage(MAX_IMAGE_NUMBER[styleSelect.value] - 1));
styleSelect.addEventListener("change", () => {
    showSpecificImage(MAX_IMAGE_NUMBER[styleSelect.value] - 1);
    removeHash();
});
saveBtn.addEventListener("click", saveImage);
videoBtn.addEventListener("click", downloadVideo);

function downloadVideo() {
    const selectedOption = styleSelect.options[styleSelect.selectedIndex];
    const productName = selectedOption.getAttribute("data-name");
    if (styleSelect.value === "jetbrainsai" || styleSelect.value === "writerside") {
        showErrorMessage("We don't have a video for this product yet :(");
        return;
    }
    const videoUrl = `${CDN_ADDRESS}/videos/${styleSelect.value}_2023_2.mp4`;

    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = `${productName}.mp4`;
    link.click();

    sendEvent("video", styleSelect.value, currentImageNumber);
}

function showRandomImage() {
    currentImageNumber = getRandomNumber(MAX_IMAGE_NUMBER[styleSelect.value]);
    loadImage(getImageUrl(styleSelect.value, currentImageNumber, true));

    sendEvent("next", styleSelect.value, currentImageNumber);
}

function toggleSaveButton(enabled) {
    saveBtn.disabled = !enabled;
}

function getImageUrl(style, number, thumbnail = false) {
    if (thumbnail) {
        return `${CDN_ADDRESS}/thumbnail/${style}/${number}.png`;
    }
    return `${CDN_ADDRESS}/${style}/${number}.png`;
}

function getRandomNumber(max) {
    return Math.floor(Math.random() * max);
}

function loadImage(url) {
    toggleSaveButton(false);
    toggleImageSpinner(true);

    const tmpImg = new Image();
    tmpImg.alt = "Generated image from Eidos - Code2Art 2023";
    tmpImg.addEventListener("load", () => {
        image.src = tmpImg.src;

        toggleImageSpinner(false);
        toggleSaveButton(true);
    }, {once: true});
    tmpImg.onerror = () => {
        showErrorMessage("Image not found. Please try again.", url);
        toggleSaveButton(false);
    };
    tmpImg.src = url;
}

function toggleImageSpinner(showSpinner) {
    spinner.style.display = showSpinner ? "block" : "none";
    if (showSpinner) {
        image.classList.add("blurred");
    } else {
        image.classList.remove("blurred");
    }
}

function showErrorMessage(message, url = "", timeout = 2000) {
    if (url) {
        errorMessage.textContent = `${message} URL: ${url}`;
    } else {
        errorMessage.textContent = message;
    }
    errorMessage.style.display = "block";
    spinner.style.display = "none";
    setTimeout(() => {
        errorMessage.style.display = "none";
    }, timeout);
}

async function saveImage() {
    toggleImageSpinner(true); // Show the loader when the Save button is clicked

    const link = document.createElement("a");
    const selectedOption = styleSelect.options[styleSelect.selectedIndex];
    const productName = selectedOption.getAttribute("data-name");
    link.download = `${productName}.png`;

    try {
        // Fetch the image as a Blob
        const imageURL = getImageUrl(styleSelect.value, currentImageNumber, false);

        const response = await fetch(imageURL);
        if (!response.ok) {
            throw new Error("Failed to fetch image");
        }
        const blob = await response.blob();
        link.href = URL.createObjectURL(blob);
    } catch (error) {
        showErrorMessage("Unable to save the image. Please try again.");
        toggleImageSpinner(false); // Hide the spinner if there's an error
        return;
    }

    link.click();
    await sendEvent("save", styleSelect.value, currentImageNumber);
    toggleImageSpinner(false); // Hide the spinner after the image is saved
}

function populateStyleSelect() {
    const selectedIndex = getRandomNumber(products.length) - 1;

    products.forEach((style, index) => {
        const option = document.createElement('option');
        option.value = style.value;
        option.textContent = style.name;
        option.setAttribute("data-name", style.name);

        option.selected = (index === selectedIndex);
        styleSelect.appendChild(option);
    });

    // Show initial image and product after populating the dropdown
    currentImageNumber = getRandomNumber(MAX_IMAGE_NUMBER[styleSelect.value]);
    const productFromHash = getProductFromHash();
    if (productFromHash) {
        showSpecificImage(MAX_IMAGE_NUMBER[productFromHash] - 1, productFromHash);
    } else {
        showSpecificImage(MAX_IMAGE_NUMBER[styleSelect.value] - 1);
    }
}

async function sendEvent(action, style, imageNumber) {
    const payload = {
        event_type: {
            category: "eidos-public",
            action: action
        },
        timestamp: new Date().toISOString(),
        user: "eidos-public-website-v1",
        details: {
            product: style,
            image_number: imageNumber
        }
    };

    try {
        const response = await fetch(EVENTS_API_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        console.error(`Failed to send event data: ${error.message}`);
    }
}

function showSpecificImage(imageNumber, product = null) {
    if (product) {
        styleSelect.value = product;
    }
    currentImageNumber = imageNumber;
    loadImage(getImageUrl(styleSelect.value, currentImageNumber, true));

    sendEvent("next", styleSelect.value, currentImageNumber);
}

function getProductFromHash() {
    const hash = window.location.hash;
    const matches = hash.match(/#for:([\w-]+)/);
    const product = matches ? matches[1] : null;

    // Check if the product exists
    if (product && products.some(p => p.value === product)) {
        return product;
    } else {
        return null;
    }
}

function removeHash() {
    history.replaceState(null, null, ' ');
}